import React, { useState, useEffect } from "react";
import "./App.css";

import "semantic-ui-css/semantic.min.css";
import {
  Header,
  Container,
  List,
  Segment,
  Button,
  Icon
} from "semantic-ui-react";
import { LineChart, XAxis, YAxis, Line, ResponsiveContainer } from "recharts";

function App() {
  const [data, setData] = useState(null);
  const [showMore, setShowMore] = useState(false);

  const createdAt = data ? data[data.length - 1].createdAt : null;
  const temp = data ? data[data.length - 1].temp : null;

  const temps = data && data.length > 0 ? data.map(item => item.temp) : null;
  const min = temps ? temps.reduce((p, c) => (c < p ? c : p), temps[0]) : null;
  const max = temps ? temps.reduce((p, c) => (c > p ? c : p), temps[0]) : null;
  const det = temps ? temps.slice(-6).reduce((p, c) => c - p) : null;
  const trendLineRise = (det > 0.1) ? true : ((det < 0.1) ? false : null);

  let latestMeasure = new Date(Date.parse(createdAt));
  let formattedDate = latestMeasure.toLocaleString("pl", {
    weekday: "long",
    hour: "numeric",
    minute: "numeric"
  });

  useEffect(() => {
    fetchData();

    setInterval(fetchData, 60000);
  }, []);

  function fetchData() {
    fetch("http://s3.mydevil.net:56796/temp")
      .then(r => r.json())
      .then(response => setData(response.slice(-144)));
  }

  function toggleShowMore() {
    setShowMore(!showMore);
  }

  function renderListItems() {
    if (!data) {
      return null;
    }

    const items = showMore
      ? data.slice().reverse()
      : data
          .slice()
          .reverse()
          .slice(0, 6);

    return items.map(item => (
      <List.Item key={item.id}>
        <Segment>
          <List.Content>
            <List.Header as="h3">
              <strong>Temperatura: </strong>
              {item.temp} &deg;C
            </List.Header>
            <List.Description>
              Sprawdzono:
              {new Date(Date.parse(item.createdAt)).toLocaleString() || "-"}
            </List.Description>
          </List.Content>
        </Segment>
      </List.Item>
    ));
  }

  function renderChart() {
    return (
      <Container
        fluid
        style={{ margin: 0, padding: 0, width: "100%", height: "300px" }}
      >
        <ResponsiveContainer>
          <LineChart compact data={data}>
            <XAxis dataKey="date" />
            <YAxis width={20} />
            <Line type="monotone" dataKey="temp" stroke="#8884d8" />
          </LineChart>
        </ResponsiveContainer>
      </Container>
    );
  }

  return (
    <div className="App">
      <Container>
        <Segment>
          <Header as="h2">
            Ogródek
            <Header.Subheader>{formattedDate || "-"}</Header.Subheader>
          </Header>
          <Header as="h1">
            <Icon name="thermometer half" />
            {temp || "-"} &deg;C
          </Header>
        </Segment>

        <Button fluid onClick={fetchData}>
          <Icon name="refresh" /> Odśwież
        </Button>
        <Header as="h3" textAlign="center">
          Ostatnie ~24 godziny:
        </Header>
        <Segment.Group horizontal>
          <Segment>
            <Header>
              Min.
              <Header.Subheader>{min || "-"} &deg;C</Header.Subheader>
            </Header>
          </Segment>
          <Segment>
            <Header>
              Maks.
              <Header.Subheader>{max || "-"} &deg;C</Header.Subheader>
            </Header>
          </Segment>
          <Segment>
            <Header>
              Trend
              <Header.Subheader>temp. {trendLineRise ? "rośnie" : ((trendLineRise === false) ? "maleje" : "bez zmian")}</Header.Subheader>
            </Header>
          </Segment>
        </Segment.Group>
        <List>{renderListItems()}</List>
        <Button fluid onClick={toggleShowMore}>
          {showMore ? "Pokaż mniej pomiarów" : "Pokaż więcej pomiarów"}
        </Button>
        <Header as="h3" textAlign="center">
          Wykres
        </Header>
        {renderChart()}
      </Container>
    </div>
  );
}

export default App;
